import React from 'react';
import { withBaseIcon } from 'react-icons-kit';

import { ic_location_on } from 'react-icons-kit/md/ic_location_on';
import { x } from 'react-icons-kit/feather/x';
import { plus } from 'react-icons-kit/feather/plus';
import { minus } from 'react-icons-kit/feather/minus';
import { menu } from 'react-icons-kit/feather/menu';
import { thumbsUp } from 'react-icons-kit/feather/thumbsUp';
import { thumbsDown } from 'react-icons-kit/feather/thumbsDown';
import { location2 as mapPin } from 'react-icons-kit/icomoon/location2';
import { ic_gps_fixed } from 'react-icons-kit/md/ic_gps_fixed';

import { play3 } from 'react-icons-kit/icomoon/play3';
import { pause2 } from 'react-icons-kit/icomoon/pause2';
import { loop } from 'react-icons-kit/icomoon/loop';
import { lock } from 'react-icons-kit/icomoon/lock';
import { quotesLeft } from 'react-icons-kit/icomoon/quotesLeft';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { download } from 'react-icons-kit/icomoon/download';
import { ic_refresh } from 'react-icons-kit/md/ic_refresh';
import { ic_account_circle } from 'react-icons-kit/md/ic_account_circle';
import { user } from 'react-icons-kit/icomoon/user';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import { ic_star } from 'react-icons-kit/md/ic_star';
import { ic_info } from 'react-icons-kit/md/ic_info';
import { listOl } from 'react-icons-kit/fa/listOl';
import { music } from 'react-icons-kit/feather/music';
import { youtube } from 'react-icons-kit/feather/youtube';
import { volumeX } from 'react-icons-kit/feather/volumeX';
import { volume2 } from 'react-icons-kit/feather/volume2';
import { users } from 'react-icons-kit/feather/users';
import { settings } from 'react-icons-kit/feather/settings';
import { arrowDownB } from 'react-icons-kit/ionicons/arrowDownB';
import { arrowUpB } from 'react-icons-kit/ionicons/arrowUpB';
import { bag } from 'react-icons-kit/ionicons/bag';
import { ic_search } from 'react-icons-kit/md/ic_search';

const guitar = {
  viewBox: '0 0 511.963 511.963',
  children: [
    {
      name: 'path',
      attribs: {
        d: 'm129.685 329.564h30v70.636h-30z',
        transform: 'matrix(.707 -.707 .707 .707 -215.633 209.179)',
      },
      children: [],
    },
    {
      name: 'path',
      attribs: {
        d: 'm179.633 279.617h30v70.636h-30z',
        transform: 'matrix(.707 -.707 .707 .707 -165.686 229.869)',
      },
      children: [],
    },
    {
      name: 'path',
      attribs: {
        d:
          'm500.15 14.185c-11.935-11.934-30.233-15.167-45.534-8.046l-78.369 36.473-12.439 37.317-97.021 97.021c5.55-27.17-8.541-50.238-31.651-57.941-22.119-7.373-46.368 2.081-57.657 22.481l-51.361 92.8-77.234 32.488c-9.582 4.03-18.167 9.801-25.519 17.152-31.153 31.153-31.153 81.844 0 112.997l89.276 89.276c15.577 15.576 36.038 23.365 56.499 23.365s40.922-7.788 56.499-23.365c7.35-7.351 13.121-15.936 17.151-25.518l32.488-77.235 92.8-51.361c20.399-11.29 29.854-35.538 22.481-57.657-7.703-23.11-31.472-37.901-57.942-31.652l97.021-97.021 38.304-12.768 14.782-44.345 17.426-17.426c7.618-7.617 11.812-17.745 11.812-28.517.001-10.773-4.194-20.901-11.812-28.518zm-21.213 35.821-22.434 22.434-12.278 36.833-24.68 8.226-27.479-27.479 8.555-25.665 66.653-31.02c3.919-1.824 8.606-.995 11.663 2.061 1.951 1.951 3.025 4.545 3.025 7.304.001 2.761-1.074 5.355-3.025 7.306zm-207.393 235.583 69.486-13.897c9.081-1.818 18.139 3.44 21.068 12.226 2.803 8.41-.792 17.629-8.547 21.922l-101.895 56.396-36.519 86.816c-2.517 5.985-6.121 11.347-10.711 15.937-19.457 19.457-51.114 19.457-70.571 0l-89.276-89.276c-9.425-9.425-14.615-21.956-14.615-35.285s5.19-25.86 14.616-35.285c4.591-4.591 9.953-8.195 15.937-10.712l86.816-36.519 56.395-101.895c3.314-5.986 9.561-9.494 16.107-9.494 1.935 0 3.896.307 5.815.946 8.787 2.929 14.044 11.986 12.227 21.069l-13.897 69.486zm9.132-33.294-23.403-23.404 115.619-115.619 23.403 23.404z',
      },
      children: [],
    },
  ],
  attribs: {},
};

export const vars = {
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;',
    // headings: '-apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;',
    // body: '"Open Sans", sans-serif;',
    headings: '"Poppins", sans-serif;',
  },
  colors: {
    white: '#fefefe',
    black: 'hsla(250, 15%, 8%, 1)',
    grey: {
      darkest: 'hsla(250, 15%, 25%, 1)',
      darker: 'hsla(250, 15%, 40%, 1)',
      dark: 'hsla(250, 15%, 50%, 1)',
      mid: 'hsla(250, 15%, 60%, 1)',
      light: 'hsla(250, 15%, 70%, 1)',
      lighter: 'hsla(250, 15%, 80%, 1)',
      lightest: 'hsla(250, 15%, 85%, 1)',
      offwhite: 'hsla(250, 15%, 96%, 1)',
    },
    primary: {
      darkest: 'hsla(270, 60%, 20%, 1)',
      darker: 'hsla(270, 60%, 30%, 1)',
      dark: 'hsla(270, 60%, 40%, 1)',
      mid: 'hsla(270, 60%, 50%, 1)',
      light: 'hsla(270, 60%, 58%, 1)',
      lighter: 'hsla(270, 50%, 70%, 1)',
      lightest: 'hsla(270, 50%, 80%, 1)',
      offwhite: 'hsla(270, 60%, 90%, 1)',
    },
    secondary: {
      darkest: 'hsla(250, 30%, 15%, 1)',
      darker: 'hsla(250, 30%, 20%, 1)',
      dark: 'hsla(250, 30%, 30%, 1)',
      mid: 'hsla(250, 30%, 35%, 1)',
      light: 'hsla(250, 30%, 40%, 1)',
      lighter: 'hsla(250, 30%, 65%, 1)',
      lightest: 'hsla(250, 30%, 75%, 1)',
      offwhite: 'hsla(250, 30%, 85%, 1)',
    },
    tertiary: {
      // darkest: 'hsla(160, 40%, 20%, 1)',
      // darker: 'hsla(160, 40%, 40%, 1)',
      // dark: 'hsla(160, 40%, 50%, 1)',
      // mid: 'hsla(160, 40%, 60%, 1)',
      // light: 'hsla(160, 40%, 70%, 1)',
      // lighter: 'hsla(160, 40%, 80%, 1)',
      // lightest: 'hsla(160, 40%, 90%, 1)'
      darkest: 'hsla(200, 85%, 20%, 1)',
      darker: 'hsla(200, 85%, 40%, 1)',
      dark: 'hsla(200, 85%, 50%, 1)',
      mid: 'hsla(200, 85%, 60%, 1)',
      light: 'hsla(200, 85%, 70%, 1)',
      lighter: 'hsla(200, 85%, 80%, 1)',
      lightest: 'hsla(200, 85%, 90%, 1)',
    },
    aqua: {
      darkest: 'hsla(190, 40%, 20%, 1)',
      darker: 'hsla(190, 40%, 40%, 1)',
      dark: 'hsla(190, 40%, 50%, 1)',
      mid: 'hsla(190, 40%, 60%, 1)',
      light: 'hsla(190, 40%, 70%, 1)',
      lighter: 'hsla(190, 40%, 80%, 1)',
      lightest: 'hsla(190, 40%, 90%, 1)',
    },
    blue: {
      darkest: 'hsla(220, 40%, 20%, 1)',
      darker: 'hsla(220, 40%, 40%, 1)',
      dark: 'hsla(220, 40%, 50%, 1)',
      mid: 'hsla(220, 40%, 60%, 1)',
      light: 'hsla(220, 40%, 70%, 1)',
      lighter: 'hsla(220, 40%, 80%, 1)',
      lightest: 'hsla(220, 40%, 90%, 1)',
    },
    success: {
      darkest: 'hsla(120, 65%, 15%, 1)',
      darker: 'hsla(120, 65%, 25%, 1)',
      dark: 'hsla(120, 65%, 35%, 1)',
      mid: 'hsla(120, 65%, 45%, 1)',
      light: 'hsla(120, 65%, 53%, 1)',
      lighter: 'hsla(120, 65%, 65%, 1)',
      lightest: 'hsla(120, 65%, 75%, 1)',
      offwhite: 'hsla(120, 65%, 90%, 1)',
    },
    warning: {
      darkest: 'hsla(5, 65%, 20%, 1)',
      darker: 'hsla(5, 65%, 30%, 1)',
      dark: 'hsla(5, 65%, 40%, 1)',
      mid: 'hsla(5, 65%, 50%, 1)',
      light: 'hsla(5, 75%, 60%, 1)',
      lighter: 'hsla(5, 75%, 70%, 1)',
      lightest: 'hsla(5, 75%, 80%, 1)',
    },
    gold: '#ffd700',
  },
  shadows: [
    '0 10px 40px -15px rgba(0, 0, 0, 0.8)',
    '0 10px 30px -5px rgba(0,0,0,0.3)',
  ],
  spacing: ['5px', '10px', '20px', '40px', '60px', '80px'],
  // spacing: ['.8rem', '1.6rem', '2rem', '4rem'],
  // borderRadius: ['6px', '12px', '24px'],
  borderRadius: ['4px', '8px', '16px', '24px'],
  break: {
    xs: '400px',
    sm: '560px',
    md: '780px',
    lg: '960px',
    xl: '1200px',
    xxl: '1500px',
  },
  // media: {
  //   mobile: rules => css`
  //     @media (max-width: 400px) {
  //       ${rules};
  //     }
  //   `,
  //   tablet: rules => css`
  //     @media (min-width: 400px) {
  //       ${rules};
  //     }
  //   `,
  //   desktop: rules => css`
  //     @media (min-width: 800px) {
  //       ${rules};
  //     }
  //   `
  // }
};

export const themeDark = `
background-color: ${vars.colors.black};
color: ${vars.colors.grey.lightest};
h1,h2,h3,h4,h5,h6 {
  color: ${vars.colors.grey.lightest};
}
a {
  color: ${vars.colors.primary.mid};
}
`;

const Icon = withBaseIcon({
  size: 20,
  // style: { color: vars.colors.light },
});
export const IconLocation = () => <Icon icon={ic_location_on} />;
export const IconClose = props => <Icon icon={x} {...props} />;
export const IconAdd = props => <Icon icon={plus} {...props} />;
export const IconMinus = props => <Icon icon={minus} {...props} />;
export const IconMenu = props => <Icon icon={menu} {...props} />;
export const IconThumbsDown = props => <Icon icon={thumbsDown} {...props} />;
export const IconMapPin = props => <Icon icon={mapPin} {...props} />;
export const IconLocate = props => <Icon icon={ic_gps_fixed} {...props} />;
export const IconThumbsUp = props => <Icon icon={thumbsUp} {...props} />;

export const IconPlay = props => <Icon icon={play3} {...props} />;
export const IconPause = props => <Icon icon={pause2} {...props} />;
export const IconRefresh = props => <Icon icon={ic_refresh} {...props} />;
export const IconUser = props => <Icon icon={ic_account_circle} {...props} />;
export const IconDownload = props => <Icon icon={download} {...props} />;
export const IconLoop = props => <Icon icon={loop} {...props} />;
export const IconPadlock = props => <Icon icon={lock} {...props} />;
export const IconLdquo = props => <Icon icon={quotesLeft} {...props} />;
export const IconCheck = props => <Icon icon={ic_check_circle} {...props} />;
export const IconArrowUp = props => (
  <Icon icon={ic_keyboard_arrow_up} {...props} />
);
export const IconArrowDown = props => (
  <Icon icon={ic_keyboard_arrow_down} {...props} />
);
export const IconArrowRight = props => (
  <Icon icon={ic_keyboard_arrow_right} {...props} />
);
export const IconArrowLeft = props => (
  <Icon icon={ic_keyboard_arrow_left} {...props} />
);
export const IconArrowDownBlock = props => (
  <Icon icon={arrowDownB} {...props} />
);
export const IconArrowUpBlock = props => <Icon icon={arrowUpB} {...props} />;
export const IconStar = props => <Icon icon={ic_star} {...props} />;
export const IconInfo = props => <Icon icon={ic_info} {...props} />;
export const IconMusic = props => <Icon icon={music} {...props} />;
export const IconGuitar = props => <Icon icon={guitar} {...props} />;
export const IconLive = props => <Icon icon={youtube} {...props} />;
export const IconTab = props => <Icon icon={listOl} {...props} />;
export const IconMute = props => <Icon icon={volumeX} {...props} />;
export const IconUnMute = props => <Icon icon={volume2} {...props} />;
export const IconUsers = props => <Icon icon={users} {...props} />;
export const IconSettings = props => <Icon icon={settings} {...props} />;
export const IconBag = props => <Icon icon={bag} {...props} />;
export const IconSearch = props => <Icon icon={ic_search} {...props} />;
