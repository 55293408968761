import React from 'react';
import dynamic from 'next/dynamic';

import { vars } from '../lib/theme';
import replaceErrorMessage from '../lib/replaceErrorMessage';

import Button from '../components/Button';

const Modal = dynamic(() => import('../components/Modal'), { ssr: false });

const ErrorContext = React.createContext();

const ErrorProvider = props => {
  const [error, setError] = React.useState(null);
  return (
    <ErrorContext.Provider value={{ error, showErrorDialog: setError }} {...props}>
      <Modal title="Error!" isOpen={error} onClose={() => setError(null)}>
        <div dangerouslySetInnerHTML={{ __html: error && replaceErrorMessage(error) }} />
        <Button
          css={`
            margin-top: ${vars.spacing[2]};
          `}
          theme="warning"
          onClick={() => setError(null)}
        >
          Ok
        </Button>
      </Modal>
      {props.children}
    </ErrorContext.Provider>
  );
};

const useError = () => React.useContext(ErrorContext);

export { ErrorProvider, useError };
