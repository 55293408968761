import Router from 'next/router';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

export const setTokens = userData => {
  if (!process.browser) return;
  Cookies.set('authToken', userData.authToken);
  Cookies.set('refreshToken', userData.refreshToken, { expires: 30 });
};

export const unsetTokens = () => {
  if (!process.browser) return;
  Cookies.remove('authToken');
  Cookies.remove('refreshToken');
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now());
};

export const getServerCookie = (headers, cookieName) => {
  if (!headers) return null;
  if (!headers.cookie) return null;

  const foundCookie = headers.cookie.split(';').find(c => c.trim().startsWith(`${cookieName}=`));
  if (!foundCookie) return undefined;

  const val = foundCookie.split('=')[1];
  return val;
};

export const getLocalCookie = cookieName => {
  return Cookies.get(cookieName);
};

export const getCookie = (cookieName, isClient, headers) => {
  return isClient ? getLocalCookie(cookieName) : getServerCookie(headers, cookieName);
};

export const isTokenExpired = token => {
  if (!token) return true;
  if (jwtDecode(token).exp > Date.now() / 1000) return false;
  return true;
};

export const withAuthSync = WrappedComponent => {
  const Wrapper = props => {
    const syncLogout = event => {
      if (event.key === 'logout') {
        Router.push('/login');
      }
    };

    React.useEffect(() => {
      window.addEventListener('storage', syncLogout);
      return () => {
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem('logout');
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  // Wrapper.getInitialProps = async ctx => {
  //   const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
  //   return { ...componentProps };
  // };

  return Wrapper;
};

//these will be used if you expand to a provider such as Auth0
// const getQueryParams = () => {
//   const params = {};
//   window.location.href.replace(/([^(?|#)=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
//     params[$1] = $3;
//   });
//   return params;
// };
// export const extractInfoFromHash = () => {
//   if (!process.browser) {
//     return undefined;
//   }
//   const { id_token, state } = getQueryParams();
//   return { token: id_token, secret: state };
// };
