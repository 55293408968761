import styled, { css } from 'styled-components';

import LoadingSpin from './LoadingSpin';

import { vars } from '../lib/theme';

const Button = ({ loading, theme, children, ...props }) => (
  <StyledButton type="button" loading={loading} theme={theme} {...props}>
    {/* {props.loading ? <Loading type="spin" color="white" height={20} width={20} /> : props.children} */}
    {loading ? (
      <LoadingSpin
        color={
          theme === 'textOnly' ? vars.colors.grey.darkest : vars.colors.white
        }
        height={20}
        width={20}
      />
    ) : (
      children
    )}
  </StyledButton>
);

export default Button;

// Styles

const StyledButton = styled('button')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${vars.colors.primary.mid};
  border: 0px solid ${vars.colors.secondary.mid};
  border-radius: ${vars.borderRadius[1]};
  box-shadow: ${vars.shadows[0]};
  color: ${vars.colors.white};
  padding: 0.8em 2.2em;
  /* font-family: inherit; */
  font-family: ${vars.fonts.headings};
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.06em;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  text-align: center;
  -webkit-appearance: none;
  margin: 0px;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${vars.colors.primary.light};
    color: ${vars.colors.white};
  }
  &:focus {
    outline: 0;
  }
  ${props =>
    props.block &&
    css`
      display: flex;
      width: 100%;
    `}
  ${props =>
    props.slim &&
    css`
      padding: 0.5em 1em;
      /* height: 34px; */
    `};
  ${props =>
    props.flat &&
    css`
      box-shadow: none;
    `};
  ${props =>
    props.disabled &&
    css`
      /* opacity: 0.6; */
      cursor: not-allowed;
    `};
  ${props =>
    props.loading &&
    css`
      opacity: 0.8;
      cursor: progress;
    `};
  ${props => {
    switch (props.theme) {
      case 'warning':
        return css`
          background-color: ${vars.colors.warning.mid};
          /* background: linear-gradient(15deg, ${vars.colors.warning.dark}, ${vars.colors.warning.mid}); */
          &:hover {
            background-color: ${vars.colors.warning.light};
            /* background: linear-gradient(15deg, ${vars.colors.warning.darker}, ${vars.colors.warning.dark}); */
          }
        `;
      case 'success':
        return css`
          background-color: ${vars.colors.success.mid};
          /* background: linear-gradient(15deg, ${vars.colors.success.dark}, ${vars.colors.success.mid}); */
          &:hover {
            background-color: ${vars.colors.success.light};
            /* background: linear-gradient(15deg, ${vars.colors.success.darker}, ${vars.colors.success.dark}); */
          }
        `;
      case 'light':
        return css`
          background: ${vars.colors.grey.lightest};
          color: ${vars.colors.black};
          &:hover {
            background: ${vars.colors.grey.offwhite};
            color: ${vars.colors.black};
          }
        `;
      case 'textOnly':
        return css`
          padding: 0;
          box-shadow: none;
          background: transparent;
          color: ${vars.colors.grey.darkest};
          &:hover {
            background: transparent;
            color: ${vars.colors.black};
          }
        `;
      case 'warningText':
        return css`
          padding: 0;
          box-shadow: none;
          background: transparent;
          color: ${vars.colors.warning.dark};
          &:hover {
            background: transparent;
            color: ${vars.colors.warning.mid};
          }
        `;
      case 'secondary':
        return css`
          background-color: ${vars.colors.secondary.mid};
          &:hover {
            background-color: ${vars.colors.secondary.light};
          }
        `;
      case 'alt':
        return css`
          background-color: ${vars.colors.primary.light};
          /* background: linear-gradient(15deg, ${vars.colors.primary.mid}, ${vars.colors.primary.light}); */
          color: ${vars.colors.white};
          &:hover {
            background-color: ${vars.colors.primary.mid};
            /* background: linear-gradient(15deg, ${vars.colors.primary.dark}, ${vars.colors.primary.mid}); */
            color: ${vars.colors.white};
          }
        `;
      case 'facebook':
        return css`
          background-color: #3b5998;
          color: ${vars.colors.white};
          &:hover {
            background-color: #334d84;
            color: ${vars.colors.white};
          }
        `;
      case 'google':
        return css`
          background-color: #db3236;
          color: ${vars.colors.white};
          &:hover {
            background-color: #d02528;
            color: ${vars.colors.white};
          }
        `;
      case '':
        return css`
          background-color: ${vars.colors.primary.mid};
          color: ${vars.colors.grey.lightest};
          &:hover {
            background-color: ${vars.colors.primary.dark};
            color: ${vars.colors.grey.lightest};
          }
        `;
      default:
    }
  }};
`;
