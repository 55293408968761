// import he from 'he';
import decode from 'decode-html';

const replaceErrorMessage = message => {
  // console.log(message);
  const replacements = [
    { input: 'GraphQL error: ', output: '' },
    { input: '&lt;strong&gt;ERROR&lt;/strong&gt;: ', output: '' },
    // { input: '&lt;', output: '<' },
    // { input: '&gt;', output: '>' },
    { input: 'incorrect_password', output: 'Incorrect email or password' },
    { input: 'invalid_email', output: 'Incorrect email or password' },
    { input: 'invalid_username', output: 'Incorrect email or password' },
    { input: 'this username is already registered', output: 'This email is already registered' },
    { input: 'Please choose another one.', output: 'Please choose another one or sign in to your account.' }
  ];
  const replaced = decode(replaceAll(message, replacements));
  return replaced;
};

export default replaceErrorMessage;

function replaceAll(str, arr) {
  var re = new RegExp(arr.map(obj => obj.input).join('|'), 'g');

  return str.replace(re, function(matched) {
    const matchedObj = arr.find(obj => obj.input === matched);
    return matchedObj && matchedObj.output;
  });
}
